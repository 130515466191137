import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { showAppcuesFlow } from '../../Helper/appcues';

/**
 * Mapping of routes to Appcues flow IDs.
 */
const ROUTES_WITH_APPCUES: { [key: string]: string } = {
  '/cart-select-basin': 'cart-select-basin',
  '/search': 'search',
  '/company-settings': 'company-settings',
  '/members-settings': 'members-settings',
  '/my-settings': 'my-settings',
  '/notification-settings': 'notification-settings',
  '/import-export-settings': 'import-export-settings',
  '/aoi': 'aoi',
  '/alerts': 'alerts',
  '/files': 'files',
  '/subscription': 'subscription',
  '/billing': 'billing',
  // Add more routes and flow IDs here as needed
};

/**
 * Custom hook for controlling Appcues flows based on the current route.
 */
export const useAppcuesControl = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    if (ROUTES_WITH_APPCUES[currentPath]) {
      const flowId = ROUTES_WITH_APPCUES[currentPath];
      showAppcuesFlow(flowId);
    } else {
      (window as any).appcues.push(["stop"]); // Stop any Appcues flows if not needed
    }
  }, [location]);
};
