import { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { UpdateColorByField } from "../map/redux/filters";

export default function ColorByDropdown() {
    const [visible, setVisible] = useState(false);
    const target = useRef(null);

    const {
        filters: { colorByField, colorByStats },
    } = useAppSelector((state) => state);

    const activeColorByStats = colorByStats[colorByField] || [];

    const dispatch = useAppDispatch();
    const options = [
        { value: "well_status", label: "Well Status" },
        { value: "operator_name", label: "Operator" },
        { value: "reservoir", label: "Reservoir" }
    ];

    useEffect(() => {
        if (colorByField === "well_status") {
            setVisible(false);
        }
    }, [colorByField]);

    const first20 = activeColorByStats.slice(0, 20);
    const sumOfOthers = activeColorByStats
        .slice(20)
        .reduce((acc, item) => acc + item.count, 0);

    const popoverContent = (
        <div style={{ backgroundColor: "#293842", color: "white", padding: "0.5rem", borderRadius: "0.25rem", width: "300px" }}>
            <div style={{ fontSize: "1.25rem", backgroundColor: "#293842", color: "white", padding: "0.5rem" }}>
                {colorByField === "well_status" ? "Well Status" : colorByField === "operator_name" ? "Operator" : "Reservoir"} Categories
            </div>
            <div style={{ fontSize: "1.15rem", color: "white", maxHeight: "200px", overflowY: "auto", padding: "0.5rem" }}>
                {activeColorByStats.length > 0 ? (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                        {[
                            ...first20,
                            {
                                name: "Others",
                                color: "gray",
                                count: sumOfOthers
                            }
                        ].map((item, index) => (
                            <li
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "0.5rem"
                                }}
                            >
                                <span
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        backgroundColor: item.color,
                                        display: "inline-block",
                                        marginRight: "0.5rem",
                                        border: "1px solid #fff"
                                    }}
                                ></span>
                                <span style={{ flex: 1 }}>
                                    {item.name} ({item.count})
                                </span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );

    return (
        <>
            <div className="d-flex align-items-center">
                <span className="colorByLabel">COLOR BY</span>
                <Tippy
                    content={popoverContent}
                    visible={visible}
                    placement="left"
                    interactive={true}
                    trigger="manual"
                    onClickOutside={() => setVisible(false)}
                >
                    <Button
                        ref={target}
                        disabled={activeColorByStats.length === 0}
                        variant="link"
                        className="p-0 ms-2 me-2"
                        style={{
                            fontSize: "1rem",
                            color: "inherit",
                            lineHeight: "1",
                            padding: "0",
                            opacity: activeColorByStats.length === 0 ? 0.5 : 1
                        }}
                        onClick={() => setVisible(!visible)}
                    >
                        <i className="fa-solid fa-gear"></i>
                    </Button>
                </Tippy>
            </div>
            <div style={{ backgroundColor: "#131B21" }}>
                <Select
                    value={options.find(
                        (option) => option.value === colorByField
                    )}
                    options={options}
                    onChange={(selectedOption) =>
                        dispatch(UpdateColorByField(selectedOption?.value))
                    }
                    placeholder="Color By"
                    isSearchable={false}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            backgroundColor: "#131B21",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "5px",
                            width: "100%"
                        }),
                        singleValue: (styles) => ({
                            ...styles,
                            backgroundColor: "#131B21",
                            color: "#FFFFFF"
                        }),
                        menu: (styles) => ({
                            ...styles,
                            backgroundColor: "#131B21",
                            color: "#FFFFFF",
                            zIndex: 9999
                        }),
                        dropdownIndicator: (styles) => ({
                            ...styles,
                            color: "#FFFFFF"
                        }),
                        indicatorSeparator: (styles) => ({
                            ...styles,
                            backgroundColor: "#FFFFFF"
                        }),
                        clearIndicator: (styles) => ({
                            ...styles,
                            color: "#FFFFFF"
                        }),
                        valueContainer: (styles) => ({
                            ...styles,
                            backgroundColor: "#131B21",
                            color: "#FFFFFF"
                        }),
                        placeholder: (styles) => ({
                            ...styles,
                            color: "#FFFFFF"
                        }),
                        option: (styles, { isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected ? "#FFFFFF" : "#131B21",
                            color: isSelected ? "black" : "#FFFFFF",
                            "&:hover": {
                                backgroundColor: isSelected
                                    ? "#FFFFFF"
                                    : "#486374",
                                color: isSelected ? "black" : "#FFFFFF",
                                textDecoration: "underline",
                                cursor: isSelected ? "default" : "pointer"
                            }
                        })
                    }}
                />
            </div>
        </>
    );
}
